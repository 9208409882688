import React from "react"

import styled from "styled-components"
import { Navbar } from "../components/Navbar"
import { Title } from "../components/styled/Title"
import { Text } from "../components/styled/Text"
import { SmallTitle } from "../components/styled/SmallTitle"
import Layout from "../components/layout"

const Body = styled.div`
  max-width: 700px;
  margin: auto;
  padding: 0 24px;
`

const HelpPage = () => (
  <Layout>
    <Body>
      <Title>Kjør-app - personvernerklæring</Title>
      <Text>
        Denne personvernerklæringen gjelder for JCT KOLSETH (org. nr. 923 461
        752) sin app ‘Kjør’. JCT KOLSETH er databehandlingsansvarlig for
        personopplysninger som samles inn og brukes i forbindelse med bruk av
        tjenesten.
      </Text>

      <SmallTitle as="h2">
        1. Formålet med behandling av personopplysninger
      </SmallTitle>
      <Text>
        ‘Kjør’ behandler personopplysninger i overensstemmelse med
        personopplysningsloven. Formålet med behandling av personopplysninger
        ved bruk av tjenesten er å oppfylle de forpliktelsene JCT KOLSETH har
        påtatt seg for gjennomføring og monitorering av kjøreturer i tilfelle
        skadesak.
      </Text>

      <SmallTitle as="h2">2. Hvilken informasjon lagres</SmallTitle>
      <Text>
        Følgende data samles inn når du benytter deg av vår tjeneste:
        <ul>
          <li>Navn og e-postadresse</li>
          <li>
            Kjøreloggen (Start- og sluttid for hver kjøretur, samt hvorvidt
            telefonen ble låst)
          </li>
          <li>
            Betalingsstatus (Kun hvorvidt du som kunde har betalt, selve
            betalingen håndteres av Google. Behandling av kortinformasjon og
            lignende er underlagt avtale direkte mellom Google og sluttbruker)
          </li>
          <li>Anonyme tekniske data (Regnes normalt ikke som persondata)</li>
        </ul>
        Alle data og tjenester kjøres på Google Cloud Platform med lagring i EU.
      </Text>

      <SmallTitle as="h2">3. Utlevering av personopplysninger</SmallTitle>
      <Text>
        Informasjon som kan knyttes til deg som person vil ikke bli delt med
        andre.
      </Text>

      <Text>
        JCT KOLSETH kan dele personopplysningene dine med underleverandører som
        behandler personopplysningene på våre vegne (databehandlere). Overføring
        av personopplysninger til slike databehandlere skjer i medhold av
        databehandleravtaler som blant annet hindrer at databehandleren bruker
        opplysningene for andre formål. Overføring av personopplysninger til JCT
        KOLSETHs databehandlere anses ikke som utlevering.
      </Text>

      <SmallTitle as="h2">4. Innsyn, retting og sletting</SmallTitle>
      <Text>
        Du kan få innsyn i de opplysningene som er registrert om deg ved å sende
        en henvendelse til oss. Du kan også be om å få rettet opplysninger om
        deg som ikke er korrekte. Personopplysninger vil ikke bli lagret lenger
        enn det som er nødvendig for å oppfylle formålet med behandlingen. Etter
        dette vil opplysningene slettes eller anonymiseres, med mindre
        opplysningene skal eller kan oppbevares utover dette som følge av lov.
      </Text>

      <SmallTitle as="h2">5. Sikring av personopplysninger</SmallTitle>
      <Text>
        Personopplysningene lagres og behandles på en trygg og sikker måte. Det
        er etablert og dokumentert rutiner og tiltak som skal sikre
        opplysningenes integritet, tilgjengelighet og konfidensialitet i henhold
        til personopplysningsloven § 13.
      </Text>

      <SmallTitle as="h2">6. Endringer</SmallTitle>
      <Text>
        Vi kan endre denne personvernerklæringen for å følge lovmessige krav,
        samt vår egen praksis for innsamling og behandling av data.
      </Text>
    </Body>
  </Layout>
)

export default HelpPage
